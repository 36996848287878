import { Unsubscribe, isAnyOf } from "@reduxjs/toolkit";
import { onAnswerSelected, onCountdownTick, onFetchQuestions, onGameEnded, onGenerateToken, onGetUserSubscribedData, onValidateAnswers, onGetVodafoneSubscriptionToken, onSignIn } from "./triv5Slice";
import { AppStartListening } from "../app/listenerMiddleware";

export const setupTriv5Middleware = (startListening: AppStartListening): Unsubscribe => {
    const listeners = [
        startListening({
            actionCreator: onGenerateToken.fulfilled,
            effect(action, { dispatch }) {
                if(action.payload.token) {
                    dispatch(onFetchQuestions());
                }
            },
        }),

        startListening({
            actionCreator: onGameEnded,
            effect(action, { dispatch }) {
                dispatch(onValidateAnswers());
            },
        }),

        startListening({
            actionCreator: onGetUserSubscribedData.fulfilled,
            effect(action, { dispatch }) {
                if (!action.payload.globalToken) {
                    dispatch(onGetVodafoneSubscriptionToken());
                }
            },
        }),

        startListening({
            actionCreator: onSignIn,
            effect(action, { dispatch }) {
                dispatch(onGetUserSubscribedData());
            },
        }),
        
        startListening({
            matcher: isAnyOf(onAnswerSelected, onCountdownTick) ,
            effect(action, { dispatch, getState }) {
                const rootState = getState();

                const questionLength = rootState.triv5Reducer.questions.length;
                const selectAnswerIsWrong = rootState.triv5Reducer.isAnswerWrong;
                if (rootState.triv5Reducer.currentQuestionIndex === questionLength || selectAnswerIsWrong || rootState.triv5Reducer.countdown < 1) {
                    dispatch(onGameEnded());
                }
            },
        }),

        startListening({
            actionCreator: onGetVodafoneSubscriptionToken.fulfilled,
            effect(action, { dispatch, getState }) {
                const rootState = getState();
                const mobileNo = rootState.triv5Reducer.mobileNo
                const msisdn = rootState.triv5Reducer.msisdn;
                const callbackUrl = encodeURIComponent(`${window.location.origin}/signup?mobile=${mobileNo}`);

                const accessToken = action.payload.accessToken;
                window.location.assign(`${window.location.origin.replace('https:', 'http:')}/register.html?token=${accessToken}&callbackUrl=${callbackUrl}&action=signup&msisdn=${msisdn}`);
            },
        }),
    ];

    return () => {
        listeners.forEach((listener) => listener());
    }
}