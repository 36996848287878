import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ThunkExtra } from "../app/store";
import { IAnswerSelectedProps, IFetchQuestionResponse, IGenerateTokenResponse, IGetUserSubscribedDataResponse, IGetVodafoneSubscriptionTokenResponse, ITriv5State, IValidateAnswerResponse, IVerifyOTPRequest } from "../app/types";
import { whatgoesaroundcomesaround } from "../app/utils";

const initialState: ITriv5State = {
    lives: 0,
    globalToken: '',
    mobileNo: '',
    msisdn: '',
    token: '',
    maxLives: 0,
    livesLimit: 0,
    creditWon: 0,
    timeUsed: 0,
    requestStatus: 'Pending',
    currentQuestionIndex: 0,
    enterOTP: false,
    questions: [],
    countdown: 50,
    isAnswerWrong: undefined,
    isAssetsPreloaded: false,
    isAnswerCorrect: false,
    selectedAnswers: {},
};

export const onClearQuestions = createAction('triv5Slice/onClearQuestions');
export const onAssetsPreloaded = createAction('triv5Slice/onAssetsPreloaded');
export const onCountdownTick = createAction('triv5Slice/onCountdownTick');
export const onGameEnded = createAction('triv5Slice/onGameEnded');
export const onSignIn = createAction<{ mobileNo: string }>('triv5Slice/onSignIn');
export const onAnswerSelected = createAction<IAnswerSelectedProps>('triv5Slice/onAnswerSelected');
export const onGetNextQuestion = createAction('triv5Slice/onGetNextQuestion');

export const onFetchQuestions = createAsyncThunk<
    IFetchQuestionResponse, 
    void, 
    ThunkExtra
>('triv5Slice/onFetchQuestions', async (requestData, { extra, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        const { triv5Reducer } = getState();
        const response = await extra.triv5Client.getQuestions({
            globalToken: triv5Reducer.globalToken,
            mobileNo: triv5Reducer.msisdn,
            questionLimit: 5,
            token: triv5Reducer.token,
        });
        return fulfillWithValue({
            questions: response
        }, {});
    } catch (err: any) { 
        return rejectWithValue(
            { message: err.message, code: err.code },
            {}
        );
    }
});

export const onValidateAnswers = createAsyncThunk<
    IValidateAnswerResponse, 
    void, 
    ThunkExtra
>('triv5Slice/onValidateAnswers', async (requestData, { extra, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        const { triv5Reducer } = getState();

        const response = await extra.triv5Client.validateAnswers({
            globalToken: triv5Reducer.globalToken,
            mobileNo: triv5Reducer.msisdn,
            token: triv5Reducer.token,
            selectedAnswers: triv5Reducer.selectedAnswers,
            timeUsed: triv5Reducer.timeUsed,
        });

        return fulfillWithValue(response!, {});
    } catch (err: any) { 
        return rejectWithValue(
            { message: err.message, code: err.code },
            {}
        );
    }
});

export const onGenerateToken = createAsyncThunk<
    IGenerateTokenResponse, 
    void, 
    ThunkExtra
>('triv5Slice/onGenerateToken', async (requestData, { extra, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        const { triv5Reducer } = getState();
        const response = await extra.triv5Client.generateToken({
            mobileNo: triv5Reducer.msisdn,
            globalToken: triv5Reducer.globalToken
        });
        return fulfillWithValue(response, {});
    } catch (err: any) { 
        return rejectWithValue(
            { message: err.message, code: err.code },
            {}
        );
    }
});

export const onVerifyOTP = createAsyncThunk<
    boolean, 
    IVerifyOTPRequest, 
    ThunkExtra
>('triv5Slice/onVerifyOTP', async (requestData, { rejectWithValue, fulfillWithValue, extra }) => {
    try {
        const response = await extra.triv5Client.verifyOTP(requestData);
        return fulfillWithValue(response!, {});
    } catch (err: any) { 
        return rejectWithValue(
            { message: err.message, code: err.code },
            {}
        );
    }
});

export const onGetUserSubscribedData = createAsyncThunk<
    IGetUserSubscribedDataResponse, 
    void, 
    ThunkExtra
>('triv5Slice/onGetUserSubscribedData', async (requestData, { extra, getState, rejectWithValue, fulfillWithValue }) => {
    try {
        const { triv5Reducer } = getState();
        const response = await extra.triv5Client.getUserSubscribedData({
            mobileNo: triv5Reducer.msisdn,
        });
        return fulfillWithValue(response!, {});
    } catch (err: any) { 
        return rejectWithValue(
            { message: err.message, code: err.code },
            {}
        );
    }
});

export const onGetVodafoneSubscriptionToken = createAsyncThunk<
    IGetVodafoneSubscriptionTokenResponse, 
    void, 
    ThunkExtra
>('triv5Slice/onGetVodafoneSubscriptionToken', async (requestData, { rejectWithValue, fulfillWithValue, extra }) => {
    try {
        const response = await extra.triv5Client.getVodafoneSubscriptionToken();
        return fulfillWithValue(response!, {});
    } catch (err: any) { 
        return rejectWithValue(
            { message: err.message, code: err.code },
            {}
        );
    }
});

const triv5Slice = createSlice({
    name: 'triv5Slice',
    initialState,
    reducers: { },
    extraReducers: (builder) => { 
        builder.addCase(onCountdownTick, (state) => {
            state.countdown = state.countdown < 1 ? 0 : state.countdown - 1;
            state.timeUsed += 1;
        });

        builder.addCase(onGenerateToken.pending, (state) => {
            state.requestStatus = 'Loading';
        });
        builder.addCase(onGenerateToken.fulfilled, (state, action) => {
            state.requestStatus = 'Successful';
            state.lives = action.payload.lives;
            state.maxLives = action.payload.maxLives;
            state.livesLimit = action.payload.limit;
            state.token = action.payload.token;
            state.rewardPair = action.payload.rewardPair;
            state.notBilled = action.payload.notBilled;
        });
        builder.addCase(onGenerateToken.rejected, (state, action) => {
            state.requestStatus = 'Error';
        });

        builder.addCase(onFetchQuestions.pending, (state) => {
            state.requestStatus = 'Loading';
        });
        builder.addCase(onFetchQuestions.fulfilled, (state, action) => {
            state.requestStatus = 'Successful';
            state.countdown = initialState.countdown;
            state.timeUsed = initialState.timeUsed;
            state.selectedAnswers = initialState.selectedAnswers;
            state.currentQuestionIndex = initialState.currentQuestionIndex;
            
            state.questions = action.payload.questions.map((question) => {
                const token = question.token.trim();
                const h = question.h;
                const xstr = atob(token);
                return {
                    ...question,
                    answer: whatgoesaroundcomesaround(xstr, h),
                };
            });

            for(const question of state.questions) {
                state.selectedAnswers = {
                    ...state.selectedAnswers,
                    [Number(question.id)]: ''
                };
            }
        });
        builder.addCase(onFetchQuestions.rejected, (state, action) => {
            state.requestStatus = 'Error';
        });

        builder.addCase(onValidateAnswers.pending, (state) => {
            state.requestStatus = 'Loading';
        });
        builder.addCase(onValidateAnswers.fulfilled, (state, action) => {
            state.requestStatus = 'Successful';
            state.creditWon = action.payload.creditWon;
        });
        builder.addCase(onValidateAnswers.rejected, (state, action) => {
            state.requestStatus = 'Error';
        });

        builder.addCase(onAssetsPreloaded, (state, action) => {
            state.isAssetsPreloaded = true;
        });

        builder.addCase(onClearQuestions, (state, action) => {
            state.questions = [];
            state.isAnswerWrong = undefined;
        });

        builder.addCase(onAnswerSelected, (state, action) => {
            const selectedAnswer = `${action.payload.answer}${state.questions[state.currentQuestionIndex].question}`;
            const questionId = Number(state.questions[state.currentQuestionIndex].id);
            state.selectedAnswers[questionId] = action.payload.answer;
            state.isAnswerWrong = state.questions[state.currentQuestionIndex].answer !== selectedAnswer
            state.isAnswerCorrect = !state.isAnswerWrong;
        });

        builder.addCase(onGetNextQuestion, (state, action) => {
            state.currentQuestionIndex += 1;
            state.isAnswerCorrect = false;
        });

        builder.addCase(onSignIn, (state, action) => {
            const msisdn = `233${action.payload.mobileNo.substring(1)}`;
            state.mobileNo = action.payload.mobileNo;
            state.msisdn = msisdn;
            state.requestStatus = "Loading";
        });

        builder.addCase(onGetUserSubscribedData.fulfilled, (state, action) => {
            state.globalToken = action.payload.globalToken;
            if(action.payload.globalToken) {
                state.requestStatus = 'Successful';
            }
        });
    },
})

export const triv5Reducer = triv5Slice.reducer;
