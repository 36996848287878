import React from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";
import ButtonPill from "./Button";
import { translate } from "../utils/translate";
import { useSelector } from "react-redux";
import { selectTriv5State } from "../slice/triv5Selector";
import { useNavigate } from "react-router-dom";

export default function Board() {
  const navigate = useNavigate();
  const triv5State = useSelector(selectTriv5State);
  const isProcessing = triv5State.requestStatus === "Loading";

  const handleOk = () => {
    navigate('/signup');
  }
  
  return (
    <Box
      width={313}
      height={166}
      bg="#F49229"
      px={2}
      py={3}
      borderRadius={37}
      boxShadow="4px 4px 4px 0px rgba(0, 0, 0, 0.12)"
    >
      <Box
        bg="white"
        borderRadius={33}
        boxShadow="0px 4px 4px 4px rgba(254, 219, 81, 0.91) inset"
      >
        <Box pt={6}>
          <Text
            textColor="#BF6818"
            fontSize={20}
            fontWeight={700}
            textAlign="center"
            fontFamily="Burbank"
          >
            {translate('CONGRATS.YOU_WON')}
          </Text>
          <Box pb={8} display='flex' justifyContent='center'>
            {isProcessing 
              ? <Spinner color="#BF6818" mt={4} />
              : (
                <Text
                  lineHeight='1'
                  textColor="#BF6818"
                  textAlign="center"
                  fontSize={60}
                  fontFamily="Burbank"
                >
                  {translate('CONGRATS.VALUE', {
                    airtime: triv5State.creditWon
                  })}
                </Text>
              )
            }
          </Box>
        </Box>
      </Box>
      <Box mt={-4} mx={4} display='flex' justifyContent='center'>
        <ButtonPill text={translate('COMMON.OK')} px={28} isLoading={isProcessing} onClick={handleOk} />
      </Box>
    </Box>
  );
}
