import {
  Box,
  Center,
  Container,
  Image,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import ProgressBar from "../common/ProgressBar";
import BorderedText from "../common/BorderedText";
import { translate } from "../utils/translate";
import ButtonPill from "../common/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectTriv5State } from "../slice/triv5Selector";
import Timer from "../common/Timer";
import ShadowText from "../common/ShadowText";
import { useNavigate } from "react-router-dom";
import { onClearQuestions } from "../slice/triv5Slice";

const position = (value: number) => {
  switch(value) {
    case 1:
      return `${value}st`;
    case 2:
      return `${value}nd`;
    case 3:
      return `${value}rd`;
    default:
      return `${value}th`;
  }
}

export default function Sorry() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triv5State = useSelector(selectTriv5State);
  const currentIndex = triv5State.currentQuestionIndex;
  const canStillPlay = triv5State.lives !== 0 && triv5State.lives !== 1;
  const isLoading = triv5State.requestStatus === "Loading";

  const handlePlayAgain = () => {
    dispatch(onClearQuestions());
    navigate('/ready');
  }
  
  return (
    <Container centerContent>
      <Box mt={4}>
        <Box>
          <VStack align="stretch">
            <Center>
              {triv5State.countdown > 0 
                ? <Image src="/images/sad.png" />
                : <Timer value={0} variant="big" />
              }
            </Center>
            <Center>
              {triv5State.countdown > 0 && triv5State.isAnswerWrong
                ? <Image src="/images/sorry.png" />
                : <Image src="/images/oooops.png" />
              }
            </Center>
            {triv5State.countdown > 0 && triv5State.isAnswerWrong &&
              <Center position='relative'>
                <ProgressBar value={((currentIndex) / 5) * 100} variant="Big" />
                <BorderedText
                  fontSize="24px"
                  position="absolute"
                  fontWeight="700"
                  fontFamily="Burbank"
                  top='0.4'
                  letterSpacing='1.5px'
                >
                  {currentIndex}/5
                </BorderedText>
              </Center>
            }
            <Box mt="25px" minW='250px'>
              {triv5State.isAnswerWrong && triv5State.countdown > 0 && 
              <>
                <Center>
                  <ShadowText letterSpacing='1px' fontFamily="Burbank" textAlign='center'>{translate("SORRY.MESSAGE1")}</ShadowText>
                </Center>
                <Center>
                  <ShadowText letterSpacing='1px' fontFamily="Burbank">{translate("SORRY.SUB_MESSAGE1", { questionPosition: position(currentIndex) })}</ShadowText>
                </Center>
              </>
              }

              {triv5State.countdown < 1 && 
              <>
                <Center>
                  <ShadowText letterSpacing='1px' fontFamily="Burbank" textAlign='center'>{translate("SORRY.MESSAGE2")}</ShadowText>
                </Center>
              </>
              }

            {triv5State.notBilled === true && 
              <>
                <Center>
                  <ShadowText letterSpacing='1px' fontFamily="Burbank" textAlign='center'>{translate("SORRY.MESSAGE3")}</ShadowText>
                </Center>
              </>
              }

              {!canStillPlay && !triv5State.notBilled &&
              <>
                <Center mt={8}>
                  <ShadowText letterSpacing='1px' fontFamily="Burbank" textAlign='center'>{translate("SORRY.MESSAGE4")}</ShadowText>
                </Center>
                <Center>
                  <ShadowText letterSpacing='1px' fontFamily="Burbank" textAlign='center'>{translate("SORRY.SUB_MESSAGE4")}</ShadowText>
                </Center>
              </>
              }
            </Box>
            {canStillPlay && 
            <Center mt="62px">
              <ButtonPill text={translate("COMMON.PLAY")} onClick={handlePlayAgain} isLoading={isLoading} />
            </Center>
            }
          </VStack>
        </Box>
      </Box>
    </Container>
  );
}
