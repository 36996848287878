import { Box, Input, InputProps, Text } from "@chakra-ui/react";
import React from "react";
import { translate } from "../utils/translate";

interface IInputProps extends InputProps{
  label: string;
}

export default function InputBox({ label, ...rest }: IInputProps) {
  return (
    <Box>
      <Text
        align="center"
        color="#fff"
        size="xs"
        lineHeight="16px"
        fontWeight="400"
        fontFamily="foco_regular"
      >
        {label}
      </Text>
      <Box pt={11}>
        <Input
          placeholder={translate("LANDING.PLACEHOLDER")}
          backgroundColor="#77180675"
          color='#ffffff'
          px="25px"
          py="25px"
          maxW="100%"
          size="xs"
          borderRadius={12}
          border={0}
          fontWeight="400"
          lineHeight="12px"
          _focusVisible={{
            border: 0,
          }}
          {...rest}
        />
      </Box>
    </Box>
  );
}
