export const handShake = (data: string) => {
    const xdata = data.split(".")
    const xxdata = atob(xdata[0]);
    const xhx = xdata[1].split('').reverse().join('');
    const h = atob(xhx);

    const content = whatgoesaroundcomesaround(xxdata, h);
    return content;
  }

export const whatgoesaroundcomesaround = (s: any, k: any) => {
    let enc = "";
    const str = s.toString();
    for (var i = 0; i < str.length; i++) {
      var a = str.charCodeAt(i);
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }
    return enc;
}
