import React, { useState, useEffect } from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

interface ISliderProps {
  imageUrls: string[];
  imageTextUrls: string[];
}

const Slider: React.FC<ISliderProps> = ({ imageUrls, imageTextUrls }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % imageUrls.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [imageUrls.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % imageTextUrls.length);
    }, 3000);
    return () => clearInterval(interval);
  });

  const goToSlide = (slideIndex: number) => {
    setCurrentSlide(slideIndex);
  };

  return (
    <Box textAlign="center">
      <Box
        borderRadius="35px"
        bgGradient="linear(180deg, rgba(218, 94, 48, 0.70) 0%, rgba(255, 147, 90, 0.70) 84.38%)"
        boxShadow="0px 4px 4px 0px rgba(251, 244, 76, 0.29) inset;"
      >
        <Flex align="center" justify="center" height="278px">
          <Image
            src={imageUrls[currentSlide]}
            alt={`Image ${currentSlide + 1}`}
          />
        </Flex>
      </Box>
      <Flex align="center" justify="center">
        <Image
          src={imageTextUrls[currentSlide]}
          alt={`Image ${currentSlide + 1}`}
        />
      </Flex>
      <Flex align="center" justify={"center"}>
        {imageUrls.map((_, index) => (
          <Box
            key={index}
            w="2"
            h="2"
            mx="1"
            bg={index === currentSlide ? "white" : "gray.300"}
            borderRadius="50%"
            cursor="pointer"
            onClick={() => goToSlide(index)}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Slider;
