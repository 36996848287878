import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

const Root = ({ children, store }: { children: React.ReactNode; store: Store }) => {
  return (
    <ChakraProvider>
      <Provider store={store}>
        {children}
      </Provider>
    </ChakraProvider>
  );
};

export default Root;