import React from "react";
import { Image, Box, Text } from "@chakra-ui/react";

export interface IRewardProps{
  text: string;
}

export default function Reward({ text }: IRewardProps) {
  return (
    <Box position='relative'>
      <Image src="/images/reward_bg.png" mt='12px' height='60px' />
      <Box position='absolute' top={0} width='100%' display='flex' justifyContent='center'>
        <Image src="/images/currency.png" height='30px' />
      </Box>
      <Box width='100%' position='absolute' top={5}>
        <Text fontSize='32px' align="center" color='#E17F20' fontFamily='FocoBlack'>
          {text}
        </Text>
      </Box>
    </Box>
  );
}
