import React from "react";
import { Box, Text, TextProps } from "@chakra-ui/react";

export default function ShadowText(props: TextProps) {
  return (
    <Box position='relative'>
      <Text
        // position='absolute'
        fontSize={30}
        color="#370101"
        fontWeight={900}
        lineHeight="30px"
        letterSpacing={-0.45}
        fontFamily="Burbank"
        {...props}
      />
      <Text
        top='-0.5'
        position='absolute'
        fontSize={30}
        color="#fff"
        fontWeight={900}
        lineHeight="30px"
        letterSpacing={-0.45}
        fontFamily="Burbank"
        {...props}
      />
    </Box>
  );
}

// -webkit-text-stroke: 2px black;