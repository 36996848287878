import { IFetchQuestionRequest, IGenerateTokenRequest, IGenerateTokenResponse, IGetUserSubscribedDataResponse, IGetVodafoneSubscriptionTokenResponse, IIsUserSubscribedsRequest, IValidateAnswerRequest, IValidateAnswerResponse, IVerifyOTPRequest } from "./types"
import { handShake } from "./utils";

class Triv5Client  {

    apiBaseUrl: string;
    authorization: string;
    subscriptionApiBase: string;
    slackBotApi: string;

    public constructor() {
        // const environment = process.env.NODE_ENV;

        this.apiBaseUrl = 'https://api.brainwavegh.com/brainwave_api/public/api/triv5/';

        this.authorization = "Basic Ynd2MDAwMTpzeSEhQDhzaWsqLg==";

        this.subscriptionApiBase= "https://apps.mobivs.com/services/BRAINWAVE/APPS/public/api/v1/";
        this.slackBotApi = "https://hooks.slack.com/services/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";
    }

    public generateToken = async(params: IGenerateTokenRequest): Promise<IGenerateTokenResponse> => {
        try {
            const response = await fetch(`${this.apiBaseUrl}triv5GenToken/${params.mobileNo}/${params.globalToken}`, {
                mode: 'cors',
                headers: {
                  'Authorization': this.authorization,
                },
            });
          
            const data = await response.json();
            if (data?.success) { 
                return {
                    token: data.token,
                    lives: Number(data.lives),
                    maxLives: Number(data.max_lives),
                    limit: Number(data.limit),
                    rewardPair: data.time_reward_pair.map((reward: any) => {
                        return {
                            minTime: reward.min_time,
                            maxTime: reward.max_time,
                        }
                    }),
                };
              } else { 
                return {
                    token: '',
                    lives: Number(data.lives || 0),
                    maxLives: Number(data.max_lives || 0),
                    limit: Number(data.limit || 0),
                    rewardPair: [],
                    notBilled: (data?.error === "Player not billed to play.")
                };
              }
        } catch(err) {
            throw err;
        }
    }

    public validateAnswers = async(params: IValidateAnswerRequest): Promise<IValidateAnswerResponse> => {
        try {
            const response = await fetch(`${this.apiBaseUrl}validateTriv5Answers/${params.mobileNo}/${params.globalToken}/${params.token}/${params.timeUsed}`, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify(params.selectedAnswers),
                headers: {
                  'Authorization': this.authorization,
                },
            });
          
            const data = await response.json();
            if (data?.success) {
                return {
                    creditWon: data.data.credit_topup,
                    points: data.data.points,
                    totalPoints: data.data.total_points
                }
            }

            return {
                creditWon: 0,
                points: 0,
                totalPoints: 0
            };

        } catch(err) {
            throw err;
        }
    }

    public getQuestions = async(params: IFetchQuestionRequest) => {
        let data = '';
        try {
            const response = await fetch(`${this.apiBaseUrl}getTriv5Questions/${params.mobileNo}/${params.globalToken}/${params.token}/${params.questionLimit}`, {
                mode: 'cors',
                headers: {
                  'Authorization': this.authorization,
                },
            });
          
            data = await response.text();
            if (data.search("error") >= 0) { 
                this.postErrorMsgToSlack(params.mobileNo, Error("No more question"), data);
                return;
            }

            var xdata = JSON.parse(handShake(data));
            return xdata.data;

        } catch(err) {
            this.postErrorMsgToSlack(params.mobileNo, err, data);

            throw err;
        }
    }

    public subscribe = async() => {

    }

    public verifyOTP = async(params: IVerifyOTPRequest): Promise<boolean> => {
        try {
            const response = await fetch(`${this.apiBaseUrl}verifyOTP`, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify({
                    mobileNo: params.mobileNo,
                    otpCode: params.otpCode,
                    network: params.network,
                    contractId: params.contractId,
                }),
                headers: {
                  'Authorization': this.authorization,
                },
            });
          
            const data = await response.json();
            return (data?.success) ? true : false;
        } catch(err) {
            throw err;
        }
    }

    public getUserSubscribedData = async(params: IIsUserSubscribedsRequest): Promise<IGetUserSubscribedDataResponse> => {
        try {
            const response = await fetch(`${this.apiBaseUrl}verifyPlayer/${params.mobileNo}`, {
                mode: 'cors',
                method: 'GET',
                headers: {
                  'Authorization': this.authorization,
                },
            });
            const data = await response.json();
            return {
                globalToken: data?.data?.global_token
            };
        } catch(err) {
            throw err;
        }
    }

    public getVodafoneSubscriptionToken = async() : Promise<IGetVodafoneSubscriptionTokenResponse | undefined>  => {
        try {
            const response = await fetch(`https://apps.mobivs.com/TELENITY/app.php?client_id=18e704437de770082101dfcd7123e988a5ad49ad&activity=token`, {
                mode: 'cors',
                method: 'GET',
            });
            const data = await response.json();
            return {
               accessToken: data?.access_token
            };
        } catch(err) {
            throw err;
        }
    }

    postErrorMsgToSlack = async(player: any, err: any, data: any) =>{
        var myHeaders = new Headers();
        var errorMsg = `
    ======== Triv5 Failed ========
    Player: ${player}
    Error: ${err.message}
    Server Data: ${data}
    ==============================
        `;
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var raw = JSON.stringify({"text":errorMsg});
        await fetch(this.slackBotApi, {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        });
    }
    
}

export default Triv5Client;
