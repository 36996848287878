import { Box, Container, Spacer, VStack, Text, Image} from "@chakra-ui/react";
import React from "react";
import Timer from "../common/Timer";
import Reward from "../common/Reward";
import ProgressBar from "../common/ProgressBar";
import OptionButton from "../common/OptionButton";
import { translate } from "../utils/translate";
import { useDispatch, useSelector } from "react-redux";
import { selectTriv5State } from "../slice/triv5Selector";
import { useNavigate } from "react-router-dom";
import { onAnswerSelected, onCountdownTick, onGetNextQuestion } from "../slice/triv5Slice";

export default function GamePlay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triv5State = useSelector(selectTriv5State);
  const questions = triv5State.questions;
  const countdown = triv5State.countdown;
  const currentIndex = triv5State.currentQuestionIndex;
  const isAnswerWrong = triv5State.isAnswerWrong;
  const matches = (currentIndex < questions.length) ? questions[currentIndex].question.match(/\bhttps?:\/\/\S+/gi) : undefined;
  const imageUrl = matches?.[0] || '';

  const options = (currentIndex < questions.length) ? [
    questions[currentIndex].optionA,
    questions[currentIndex].optionB,
    questions[currentIndex].optionC,
    questions[currentIndex].optionD,
  ] : [];

  if (isAnswerWrong === true || triv5State.countdown < 1) {
    navigate('/sorry');
  }

  if (isAnswerWrong === false && currentIndex === questions.length) {
    navigate('/congrats');
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      dispatch(onCountdownTick());
    }, 1000);

    if (triv5State.countdown < 1) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triv5State.countdown]);

  const handleAnswerSelected = (answer: string) => {
    dispatch(onAnswerSelected({
      questionId: Number(questions[currentIndex].id),
      answer
    }));

    setTimeout(() => {
      dispatch(onGetNextQuestion());
    }, 500);
  }

  if ((currentIndex >= questions.length)) {
    return <></>
  }

  return (
      <VStack align='stretch' minH='100vh'>
        <Container centerContent>
          <Box display='flex' width='100%' px='8px' alignItems='center' mt={4}>
            <Reward text='5'/>
            <Spacer />
            <VStack gap='0'>
              <Text color='white' fontSize='20px' fontFamily='Burbank'>
                {translate('COMMON.GOAL', {questionPosition: (currentIndex + 1), totalQuestion: (questions.length)})}
              </Text>
              <ProgressBar value={((currentIndex + 1) / questions.length) * 100} />
            </VStack>
            <Spacer />
            <Timer variant="small" value={countdown} />
          </Box>
          <Box mt='34px' width='100%' borderRadius='12px' bgGradient='linear-gradient(180deg, rgba(224, 131, 46, 0.97) 0%, rgba(242, 150, 12, 0.70) 100%)' p='2'>
            <Box bgColor='#ffffff' borderRadius='10px' p='24px' minH='200px' alignItems='center' display='flex' flexDirection='column' justifyContent='center'>
              <Box width='100%'>
                <Text color='#BF6818' textAlign='center' fontSize='26px' width='100%' fontFamily='Burbank'>
                  {questions[currentIndex].question.replace(imageUrl, '')}
                </Text>
              </Box>

              {imageUrl.length > 0 && 
                <Box width='100%' justifyContent="center" display="flex">
                  <Image alt="Image question" src={imageUrl} maxH='150px' maxW='250px' />  
                </Box>
              }
            </Box>
          </Box>
        </Container>
        
        <Box position='relative' overflow='hidden' display='flex' flexGrow='1'>
          <Box left='-10%' minW='120%' minH='250px' position='absolute' bgColor='#fff' borderTopRadius='100%'></Box>
          {triv5State.isAnswerCorrect &&
            <Box textAlign='center' position='absolute' width='100%' top='20px' zIndex='1' fontFamily='Burbank' color='#3FBC44' fontSize='32px'>
              <Text>{translate('COMMON.GREAT')}</Text>
            </Box>
          }
          <Box mt='60px' pb='20px' bgColor='white' flexGrow='1'>
            {options.map((option, index) => <OptionButton isCorrect={triv5State.questions[currentIndex].answer.startsWith(option) && triv5State.isAnswerCorrect} key={index} text={option} onClick={() => handleAnswerSelected(option)} />)}
          </Box>
        </Box>
      </VStack>
  );
}
