import React from "react";
import Root from "./app/Root";
import Router from "./app/Routes";
import { createStore } from "./app/store";
import { createHashHistory } from "history";

function App() {
  const history = createHashHistory();
  const store = createStore(history);
  return (
    <Root store={store}>
      <Router />
    </Root>
  );
}

export default App;
