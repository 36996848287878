import { Center, Image, Text } from '@chakra-ui/react'
import React from 'react'

interface IOptionButton {
    text: string;
    isCorrect?: boolean;
    onClick: () => void;
}

export default function OptionButton({text, isCorrect, onClick}: IOptionButton) {
  if (text) {
    return (
      <Center position='relative' width='100%' my='6px' onClick={onClick} cursor='pointer'>
          <Image src={!isCorrect ? '/images/option_button.png' : '/images/option_button_correct.png'} />
          <Text position='absolute' color='#646463' fontSize='22px' fontFamily='Burbank'>
              {text}
          </Text>
      </Center>
    )
  }

  return <></>;
}
