import {
  AbsoluteCenter,
  Box,
  Center,
  Container,
  Image,
  VStack,
  Link,
  Text
} from "@chakra-ui/react";
import React from "react";
import Board from "../common/Board";
import { translate } from "../utils/translate";

export default function Congrats() {
  const socialIcons = [
    {
      imageUrl: "/images/twitter.png",
      link: "https://twitter.com/share?text=I killed it. 5 correct answers in a row for a cool prize. Can you beat that?&url=http://triv5.brainwavegh.com",
      altText: "Twitter",
    },
    {
      imageUrl: "/images/facebook.png",
      link: "https://www.facebook.com/sharer/sharer.php?message=I killed it. 5 correct answers in a row for a cool prize. Can you beat that&u="+encodeURIComponent("http://triv5.brainwavegh.com"),
      altText: "Facebook",
    },
    {
      imageUrl: "/images/whatsapp.png",
      link: "https://api.whatsapp.com/send?text=I%20killed%20it.%205%20correct%20answers%20in%20a%20row%20for%20a%20cool%20prize.%20Can%20you%20beat%20that.%20"+encodeURIComponent("http://triv5.brainwavegh.com"),
      altText: "Whatsapp",
    },
  ];

  return (
    <Container centerContent>
      <Box height="100vh" mt={4}>
        <Box>
          <VStack align="stretch">
            <Center>
              <Image src="/images/happy.png" />
            </Center>
            <Center>
              <Image src="/images/congrats.png" />
            </Center>
            <Center>
              <Board />
            </Center>

            <Center mt={24}>
              <Box>
                <Text fontFamily='Burbank' fontSize='26px' color='#fff'>{translate("COMMON.SHARE")}</Text>
              </Box>
            </Center>

            <Center>
              <Box
                width={175}
                height={65}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {socialIcons.map((socialIcon) => (
                  <Link href={socialIcon.link} isExternal>
                    <Box
                      bg="#fff"
                      width="52px"
                      height="52px"
                      borderRadius={40}
                      position="relative"
                    >
                      <AbsoluteCenter textAlign="center">
                        <Image
                          src={socialIcon.imageUrl}
                          alt={socialIcon.altText}
                        />
                      </AbsoluteCenter>
                    </Box>
                  </Link>
                ))}
              </Box>
            </Center>
          </VStack>
        </Box>
      </Box>
    </Container>
  );
}
