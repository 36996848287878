import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import Ready from "../pages/Ready";
import Congrats from "../pages/Congrats";
import GamePlay from "../pages/GamePlay";
import Sorry from "../pages/Sorry";
import Signup from "../pages/Signup";
import { useSelector } from "react-redux";
import { selectTriv5State } from "../slice/triv5Selector";

const Router = () => {
  const triv5State = useSelector(selectTriv5State);
  const isAssetsPreloaded = triv5State.isAssetsPreloaded;
  const token = triv5State.token;
  
  const urlParams = new URLSearchParams(window.location.search);
  const mobile = urlParams.get('mobile')?.split('?')[0];

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        
        <Route element={ 
            token !== '' ? (
              <GamePlay />
            ) : (
              <Navigate to={{ pathname: '/' }} />
            )
          } 
          path="/play" 
        />

        <Route
          element={ 
            isAssetsPreloaded ? (
              <Ready />
            ) : (
              <Navigate to={{ pathname: '/' }} />
            )
          }
          path="/ready"
        />

        <Route
          element={ 
            token !== '' ? (
              <Congrats />
            ) : (
              <Navigate to={{ pathname: '/' }} />
            )
          }
          path="/congrats"
        />

        <Route
          element={ 
            isAssetsPreloaded ? (
              <Sorry />
            ) : (
              <Navigate to={{ pathname: '/' }} />
            )
          }
          path="/sorry"
        />

        <Route
          element={ 
            isAssetsPreloaded ? (
              <Signup />
            ) : (
              <Navigate to={{ pathname: '/' }} state={{mobile}} />
            )
          }
          path="/signup"
        />

      </Routes>
    </BrowserRouter>
  );
};

export default Router;
