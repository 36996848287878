import React, { useState, useEffect } from "react";
import {
  AbsoluteCenter,
  Box,
  Center,
  Container,
  Image,
  VStack,
} from "@chakra-ui/react";
import ProgressBar from "../common/ProgressBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onAssetsPreloaded } from "../slice/triv5Slice";

export default function Home() {
  const [imagesLoaded, addLoadedImage] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const imageUrls: string[] = [
    "/images/reward.png",
    "/images/sad.png",
    "/images/happy.png",
    "/images/brainwave_triv5_logo.png",
    "images/congrats.png",
    "images/currency.png",
    "images/facebook.png",
    "images/hour_glass.png",
    "images/oooops.png",
    "images/option_button_correct.png",
    "images/option_button.png",
    "images/ready.png",
    "images/reward_bg.png",
    "images/sorry.png",
    "images/think_fast.png",
    "images/timer.png",
    "images/triv5.png",
    "images/twitter.png",
    "images/whatsapp.png",
    "images/win_instantly.png",
  ];
  const mobile = location.state?.mobile;

  const handleImageLoaded = (index: number) => {
    addLoadedImage(images => [...images, imageUrls[index]]);
  }

  useEffect(() => {
    if (imagesLoaded.length === imageUrls.length) {
      dispatch(onAssetsPreloaded());
      mobile ? navigate(`/signup?mobile=${mobile}`) : navigate('/signup');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesLoaded])

  return (
    <Container centerContent>
      <Box minH="100vh" w="100%" maxW="768px">
        <AbsoluteCenter>
          <VStack align="stretch">
            <Center>
              <Image src="/images/triv5.png" height="60px" />
            </Center>

            <Center>
              <ProgressBar value={(imagesLoaded.length / imageUrls.length) * 100} />
            </Center>

            {imageUrls.map((e, index) => (
              <img alt='' src={e} style={{ display: "none" }} onLoad={() => handleImageLoaded(index)} />
            ))}
          </VStack>
        </AbsoluteCenter>
      </Box>
    </Container>
  );
}
