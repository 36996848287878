import React from "react";
import { Image, Box } from "@chakra-ui/react";
import BorderedText from "./BorderedText";

interface ITimerProps {
  variant: 'big' | 'small';
  value: number;
}

export default function Timer({ variant, value }: ITimerProps) {
  return (
    <Box position="relative">
      <Image src="/images/timer.png" width={variant === 'big' ? '114px' : '60px'} />
      <Box position="absolute" width='100%' height='100%' alignItems='center' justifyContent='center' top={variant === 'big' ? '2' : '1'} display='flex'>
        <BorderedText fontSize={variant === 'big' ? '50px' : '26px'} align="center" fontFamily="FocoBlack">
          {value}
        </BorderedText>
      </Box>
    </Box>
  );
}
