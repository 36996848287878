import { SerializedError, configureStore, createAction } from '@reduxjs/toolkit';
import Triv5Client from './client';
import { triv5Reducer } from '../slice/triv5Slice';
import createAppListenerMiddleware from './listenerMiddleware';
import { History } from 'history';

export const createStore = (history: History) => {
  const store = configureStore({
    reducer: {
      triv5Reducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            triv5Client: new Triv5Client(),
          },
        },
      }).prepend(createAppListenerMiddleware(history).middleware)
  });

  return store;
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>;

export type AppDispatch = ReturnType<typeof createStore>['dispatch'];

export interface ThunkExtra {
  extra: {
    triv5Client: Triv5Client;
  };
  state: RootState;
  rejectValue: SerializedError;
  rejectedMeta: Record<string, any>;
  fulfilledMeta: Record<string, any>;
}
