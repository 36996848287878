import {
  Box,
  Center,
  Container,
  VStack,
  Image,
} from "@chakra-ui/react";
import React from "react";
import InputBox from "../common/Input";
import { translate } from "../utils/translate";
import ButtonPill from "../common/Button";
import Slider from "../common/Slider";
import { useDispatch, useSelector } from "react-redux";
import { onSignIn } from "../slice/triv5Slice";
import { selectTriv5State } from "../slice/triv5Selector";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Signup() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const mobile = searchParams.get("mobile") || '';
  const [number, setNumber] = React.useState(mobile);
  const triv5State = useSelector(selectTriv5State);
  const isLoading = triv5State.requestStatus === "Loading";
  const dispatch = useDispatch<any>();

  if(triv5State.globalToken) {
    navigate('/ready');
  }

  const handleOnSignup = async() => {
    if (number.trim().length < 10) return;
    await dispatch(onSignIn({
      mobileNo: number,
    }));
  }

  const onHandleTextChange = (value: string) => {
    setNumber(value);
  }

  const imageUrls = ["/images/hour_glass.png", "/images/reward.png"];
  const imageTextUrls = ["/images/think_fast.png", "/images/win_instantly.png"];
  return (
    <Container centerContent>
      <Box mt={16}>
        <Box width='100%'>
          <VStack align="stretch">
            <Center>
              <Image src="/images/brainwave_triv5_logo.png" height="32px" />
            </Center>
            <Center mt="24px">
              <Box height="314px">
                <Slider imageUrls={imageUrls} imageTextUrls={imageTextUrls} />
              </Box>
            </Center>
            <Center mt={20}>
              <InputBox label={translate("LANDING.LABEL")} value={number} onChange={(event) => onHandleTextChange(event.target.value)} minW='350px' maxW='100%' />
            </Center>
            <Center mt={9}>
              <ButtonPill
                text={translate("COMMON.LETSGO")}
                onClick={handleOnSignup}
                isLoading={isLoading}
              />
            </Center>
          </VStack>
        </Box>
      </Box>
    </Container>
  );
}
