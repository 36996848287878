import React from "react";
import { Text, TextProps } from "@chakra-ui/react";

export default function BorderedText(props: TextProps) {
  return (
    <Text
      fontSize={30}
      color="#fff"
      fontWeight={900}
      lineHeight="30px"
      letterSpacing={-0.45}
      fontFamily="foco black"
      textShadow='-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
      {...props}
    />
  );
}

// -webkit-text-stroke: 2px black;