import { TypedStartListening, createListenerMiddleware } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "./store";
import { setupTriv5Middleware } from "../slice/triv5MiddlewareLisener";
import { History } from 'history';

const createAppListenerMiddleware = (history: History) => {
    const listenerMiddlewareInstance = createListenerMiddleware({
        onError: () => {

        },
        extra: { history },
    });

    setupTriv5Middleware(listenerMiddlewareInstance.startListening as AppStartListening);
    
    return listenerMiddlewareInstance;
}

interface MiddlewareExtra {
    history: History;
}

export type AppStartListening = TypedStartListening<RootState, AppDispatch, MiddlewareExtra>;

export default createAppListenerMiddleware;
