import React from "react";
import {
  Box,
  Center,
  Container,
  VStack,
  Image,
} from "@chakra-ui/react";
import Timer from "../common/Timer";
import ButtonPill from "../common/Button";
import { translate } from "../utils/translate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onGenerateToken } from "../slice/triv5Slice";
import { selectTriv5State } from "../slice/triv5Selector";
import ShadowText from "../common/ShadowText";

export default function Ready() {
  const dispatch = useDispatch<any>();
  const triv5State = useSelector(selectTriv5State);
  const questions = triv5State.questions;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);

  if(triv5State.requestStatus === "Successful" && !triv5State.token) {
    navigate('/sorry');
  }

  React.useEffect(() => {
    if(questions.length > 0) {
      downloadImagesFromQuestions();
    }
  }, [questions]);

  const downloadImagesFromQuestions = async() => {
    const imageUrls = [];
    for (const item of questions) {
      let question = item.question;
      const matches = question.match(/\bhttps?:\/\/\S+/gi) || [];
      if (matches.length > 0) { 
        imageUrls.push(matches[0]);
      }
    }

    const promises: Promise<void>[] = imageUrls.map(async(imageUrl) => {
      return new Promise<void>((resolve) => {
        const img = document.createElement("img");
        img.src = imageUrl!;
        img.onload = () => {
          resolve();
        };
      });
    });

    await Promise.all(promises);
    setIsLoading(false);
    navigate('/play');
  }

  const handleOnStart = () => {
    setIsLoading(true);
    dispatch(onGenerateToken());
  }

  return (
    <Container centerContent>
      <Box mt={4}>
          <VStack align="stretch">
            <Center>
              <Box>
                <Timer variant="big" value={50} />
              </Box>
            </Center>
            <Center>
              <Image src="/images/ready.png" />
            </Center>
            <Box>
              <Center>
                <ShadowText align="center" fontFamily="Burbank">
                  {translate("COUNTDOWN.RULE1")} <br />
                  {translate("COUNTDOWN.RULE2")}
                </ShadowText>
              </Center>
            </Box>
            <Center mt={16}>
              <ButtonPill text={translate("COMMON.START")} onClick={handleOnStart} isLoading={isLoading} />
            </Center>
          </VStack>
      </Box>
    </Container>
  );
}
