import { Button, ButtonProps, Text, Spinner } from "@chakra-ui/react";
import React from "react";

interface IButtonProp extends ButtonProps {
  text: string;
  isLoading?: boolean;
  onClick?: () => void;
}
export default function ButtonPill({
  text,
  onClick,
  isLoading,
  ...props
}: IButtonProp) {
  return (
    <Button
      disabled={isLoading}
      border={0}
      bgGradient="linear(#FFD561, #FE9F1F, #FEA323, #FEAD31, #FFBF47, #FFD561)"
      px={51}
      py={7}
      borderRadius={30}
      onClick={isLoading ? undefined : onClick}
      {...props}
      _active={{
        bgGradient:
          "linear(#FFD561, #FE9F1F, #FEA323, #FEAD31, #FFBF47, #FFD561)",
      }}
      _hover={{
        bgGradient:
          "linear(#FFD561, #FE9F1F, #FEA323, #FEAD31, #FFBF47, #FFD561)",
      }}
    >
      {isLoading && <Spinner color="white" />}
      {!isLoading && <Text fontFamily='FocoBlack' fontSize='30px' color='#ffffff'>{text}</Text>}
    </Button>
  );
}
