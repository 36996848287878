import React from 'react'
import { Box } from "@chakra-ui/react";

interface IProgressBar {
  value:  number;
  variant?: 'Big' | 'Small';
}
export default function ProgressBar({value, variant = 'Small'}: IProgressBar) {
  return (
      <Box>
        <Box 
          borderRadius='24px'
          width={variant === 'Big' ? '162px' : '106px'}
          border='2px solid #F3C480'
          bgColor='#ffffff'
          py='2px'
          px='2px'
        >
          <Box 
            bgGradient="linear(#FE9F1F, #FEA323, #FEAD31, #FFBF47, #FFD561)" 
            borderRadius='24px'
            width={`${((value/100) * (variant === 'Big' ? 152 : 98))}px`} 
            height={variant === 'Big' ? '20px' : '13px'}
          />
        </Box>
      </Box>

  )
}
